/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-02-18 11:46:59
 */
import config from './config/center'
import axios from '@/utils/request'
export const centerList = params => axios.post(config.centerList, params)
export const courseList = params => axios.post(config.courseList, params)
export const classList = params => axios.post(config.classList, params)
export const treeList = params => axios.post(config.treeList, params)
export const productInfo = params => axios.post(config.productInfo, params)
export const productCreate = params => axios.post(config.productCreate, params)
export const productPublish = params => axios.post(config.productPublish, params)
export const productUpdate = params => axios.post(config.productUpdate, params)
export const priceUpdate = params => axios.post(config.priceUpdate, params)
export const svCategory = params => axios.post(config.svCategory, params)
export const svList = params => axios.post(config.svList, params)
export const svSave = params => axios.post(config.svSave, params)
export const productList = params => axios.post(config.productList, params)
export const productDetail = params => axios.post(config.productDetail, params)
export const svInfo = params => axios.post(config.svInfo, params)
export const svUpdate = params => axios.post(config.svUpdate, params)
export const productCertPage = params => axios.post(config.productCertPage, params)
export const productCertCategory = params => axios.post(config.productCertCategory, params)
export const listReviewByCategory = params => axios.post(config.listReviewByCategory, params)
export const productCertCreate = params => axios.post(config.productCertCreate, params)
export const productCertInfoSave = params => axios.post(config.productCertInfoSave, params)
export const productCertSpecList = params => axios.post(config.productCertSpecList, params)
export const productSkuSave = params => axios.post(config.productSkuSave, params)
export const productSkuRemove = params => axios.post(config.productSkuRemove, params)
export const productCertInfo = params => axios.post(config.productCertInfo, params)
export const productCertSubmit = params => axios.post(config.productCertSubmit, params)
export const productCertCategoryTree = params => axios.post(config.productCertCategoryTree, params)
export const productCertExport = params =>
  axios.post(config.productCertExport, params, { responseType: 'blob' })
export const productCertPublish = params => axios.post(config.productCertPublish, params)
export const productRemove = params => axios.post(config.productRemove, params)
export const agentInfo = params => axios.post(config.agentInfo, params)
export const agentSumAmount = params => axios.post(config.agentSumAmount, params)
export const agentOrderPage = params => axios.post(config.agentOrderPage, params)
export const agentOrderExport = params =>
  axios.post(config.agentOrderExport, params, { responseType: 'blob' })
export const offlineSalesRecord = params => axios.post(config.offlineSalesRecord, params)
export const offlineSalesRecordExport = params =>
  axios.post(config.offlineSalesRecordExport, params, { responseType: 'blob' })
export const offlineSalesRecordImport = params =>
  axios.post(config.offlineSalesRecordImport, params)
export const offlineSalesRecordDownload = params =>
  axios.post(config.offlineSalesRecordDownload, params, { responseType: 'blob' })
export const offlineSalesRecordProductSku = params =>
  axios.post(config.offlineSalesRecordProductSku, params)
export const offlineSalesRecordReg = params => axios.post(config.offlineSalesRecordReg, params)
export const distributorList = params => axios.post(config.distributorList, params)
export const offlineSalesRecordRegExport = params =>
  axios.post(config.offlineSalesRecordRegExport, params, { responseType: 'blob' })
export const offlineSalesRecordReview = params =>
  axios.post(config.offlineSalesRecordReview, params)
export const offlineSalesRecordDelete = params =>
  axios.post(config.offlineSalesRecordDelete, params)
export const certCategoryList = params => axios.post(config.certCategoryList, params)
export const findProductSaleInfo = params => axios.post(config.findProductSaleInfo, params)
export const getAllPayType = params => axios.post(config.getAllPayType, params)
export const exportProductSaleInfo = params =>
  axios.post(config.exportProductSaleInfo, params, { responseType: 'blob' })
export const getSelectButtonProductSkuInfo = params =>
  axios.post(config.getSelectButtonProductSkuInfo, params)
