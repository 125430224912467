<template>
  <div class="import-approval">
    <table-list
      title="分销数据"
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :operates="operates(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </table-list>
  </div>
</template>

<script>
// 顶部按钮
const btns = _this => [
  {
    label: '添加报名记录',
    method: _this.handleAdd,
  },
  {
    label: '导出',
    method: _this.handleexportData,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'productName',
    label: '证书商品名称',
    minWidth: 160,
    twoLines: true,
  },
  {
    prop: 'skuName',
    label: '服务名称',
    minWidth: 110,
  },
  {
    prop: 'orderAmount',
    label: '商品金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.orderAmount
    },
  },
  {
    prop: 'payAmount',
    label: '实付金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.payAmount
    },
  },
  {
    prop: 'userName',
    label: '购买人姓名',
    minWidth: 90,
  },
  {
    prop: 'phone',
    label: '手机号',
    minWidth: 110,
  },
  {
    prop: 'payType',
    label: '支付渠道',
    minWidth: 76,
  },
  {
    prop: 'commitTime',
    label: '导入时间',
    minWidth: 150,
  },
  {
    prop: 'examineStatusShow',
    label: '审核状态',
    minWidth: 80,
  },
  {
    prop: 'examineTime',
    label: '审核时间',
    minWidth: 150,
    formatter: row => {
      return row.examineTime || '—'
    },
  },
  {
    prop: 'remarks',
    label: '备注',
    minWidth: 160,
    formatter: row => {
      return row.remarks || '—'
    },
  },
  {
    prop: 'examineUserName',
    label: '审核人',
    minWidth: 62,
    formatter: row => {
      return row.examineUserName || '—'
    },
  },
]
// 表格操作按钮配置
const operates = _this => [
  {
    label: '删除',
    method: _this.handleDelete,
    isShow: row => row.examineStatus == 0 || row.examineStatus == 2,
  },
]
import TableList from '@/components/TableList'
import {
  offlineSalesRecord,
  offlineSalesRecordExport,
  offlineSalesRecordDelete,
} from '@/api/center'
import to from 'await-to'
export default {
  name: 'SalesRecord',
  components: {
    TableList,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      btns,
      columns,
      operates,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      dataList: [],
      sunAmount: 0,
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        offlineSalesRecord({
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.getList()
    },
    handleAdd() {
      this.$router.push('/scenter/mySalesRecord/add')
    },
    handleDelete(row) {
      this.$confirm('确认删除记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const [, err] = await to(offlineSalesRecordDelete({ ids: [row.id] }))
        if (err) return this.$message.warning(err.msg)
        this.$message.success('删除成功')
        this.getList()
      })
    },
    //导出
    async handleexportData() {
      const { current, size } = this.pager
      const [res, err] = await to(
        offlineSalesRecordExport({
          current,
          size,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      console.log(res)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = this.userName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
.sales-record {
  .subTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid #e2e2e2;
    padding-left: 20px;
    line-height: 20px;
    .textDes {
      color: #909399;
    }
    .textMon {
      color: #ff7b33;
    }
  }
  ::v-deep .table-list {
    .top-bar {
      padding: 14px 0;
    }
  }
}
</style>
