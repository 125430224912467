<template>
  <div class="my-sales-record">
    <div class="header">
      <div class="headimage">
        <el-avatar :size="60" :src="agentDetail.headPic"></el-avatar>
        <p>{{ agentDetail.distributorName || '—' }}</p>
      </div>
      <div class="headText">
        <div>
          <span class="colr1 ph1">手机号码：</span>
          <span class="colr2">{{ agentDetail.mobile || '—' }}</span>
        </div>
        <div class="item-cardId">
          <span class="colr1">身份证号码：</span>
          <span class="colr2">{{ agentDetail.cardId || '—' }}</span>
        </div>
        <div>
          <span class="colr1 ph1">所属地区：</span>
          <span class="colr2">{{ agentDetail.location || '—' }}</span>
        </div>
      </div>
      <div class="headInfo">
        <div>
          <span class="colr1">代理等级：</span>
          <span v-if="agentDetail.agencyLevel == 1" class="colr2">一级</span>
          <span v-if="agentDetail.agencyLevel == 2" class="colr2">二级</span>
        </div>
        <div class="addtime">
          <span class="colr1">添加时间：</span>
          <span class="colr2">{{ agentDetail.createTime || '—' }}</span>
        </div>
        <div class="action">
          <span class="colr1 ph2 texttop">备注：</span>
          <span class="colr2 text">{{ agentDetail.remark || '—' }}</span>
        </div>
      </div>
      <div class="headCardImage">
        <span>相关资质：</span>
        <span v-if="agentDetail.appendixUrl == ''">—</span>
        <div v-if="agentDetail.appendixUrl != ''">
          <img :src="agentDetail.appendixUrl" />
        </div>
      </div>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="销售记录" name="1">
        <SalesRecord :user-name="agentDetail.distributorName" />
      </el-tab-pane>
      <el-tab-pane label="线下导入审核" name="2">
        <ImportApproval :user-name="agentDetail.distributorName" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import SalesRecord from './components/SalesRecord.vue'
import ImportApproval from './components/ImportApproval.vue'
import { agentInfo } from '@/api/center'
import to from 'await-to'
export default {
  name: 'MySalesRecord',
  components: {
    SalesRecord,
    ImportApproval,
  },
  data() {
    return {
      activeName: '1',
      agentDetail: {},
    }
  },
  mounted() {
    this.handleSearchAgentDetail()
    if (this.$route.query.active) {
      this.activeName = this.$route.query.active
    }
  },
  methods: {
    //查询代理商详情
    async handleSearchAgentDetail() {
      const [res, err] = await to(agentInfo({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.agentDetail = res.data
    },
    handleClick() {},
  },
}
</script>
<style scoped lang="scss">
.header {
  width: 100%;
  height: 156px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  .headimage {
    margin: 20px 0 0 22px;
    text-align: center;
    .el-avatar {
      display: block;
      margin-bottom: 12px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: #333333;
    }
  }
  .headText {
    margin: 20px 0 0 5.31%;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .colr1 {
      color: #606266;
    }
    .colr2 {
      color: #333333;
    }
    .ph1 {
      padding-left: 14px;
    }
    .item-cardId {
      margin: 24px 0;
    }
  }
  .headInfo {
    margin: 20px 0 0 10.42%;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
    }
    .colr1 {
      color: #606266;
    }
    .colr2 {
      color: #333333;
    }
    .ph2 {
      padding-left: 28px;
    }
    .addtime {
      margin: 24px 0;
    }
    .action {
      display: flex;
      .texttop {
        padding-top: 2px;
      }
      .text {
        display: block;
        width: 230px;
        line-height: 18px;
      }
    }
  }
  .headCardImage {
    margin: 20px 0 0 11.98%;
    display: flex;
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      color: #606266;
    }
    img {
      width: 116px;
      height: 116px;
      opacity: 1;
      border-radius: 0px;
    }
  }
}

::v-deep.el-tabs {
  .el-tabs__header {
    margin-bottom: 0;
  }
  .el-tabs__item {
    height: 60px;
    line-height: 90px;
  }
}
::v-deep .table-list {
  .top-bar {
    padding: 14px 0;
  }
}
@media screen and (max-width: 1300px) {
  .header {
    .headText {
      margin: 20px 0 0 3.31%;
    }
    .headInfo {
      margin: 20px 0 0 8.42%;
    }
    .headCardImage {
      margin: 20px 0 0 8.98%;
    }
  }
}
@media screen and (max-width: 1300px) {
  .header {
    .headText {
      margin: 20px 0 0 3.31%;
    }
    .headInfo {
      margin: 20px 0 0 5.42%;
    }
    .headCardImage {
      margin: 20px 0 0 5.98%;
    }
  }
}
</style>
