/*
 * @Description:
 * @Author: xiawenlong
 * @Date: 2021-05-17 08:36:40
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-02-18 11:46:43
 */
export default {
  centerList: '/course/supply/product/page', // 销售中心列表
  courseList: '/course/supply/product/create/courses/list', // 课程列表
  classList: '/course/supply/product/create/class/list', // 班级列表
  treeList: '/course/supply/product/category/list', // 商品分类
  productInfo: '/course/supply/product/create/resource/info', //商品信息查询
  productCreate: '/course/supply/product/create', //创建商品
  productPublish: '/course/supply/product/publish', //上架下架商品
  productUpdate: '/course/supply/product/update', //修改商品信息
  priceUpdate: '/course/supply/product/price/update', //修改商品价格
  svCategory: '/course/supply/sv/category', //短视频分类
  svList: '/course/supply/sv/page', //短视频列表
  svSave: '/course/supply/sv/save', //发布短视频
  productList: '/course/supply/sv/product/list', //短视频商品列表
  productDetail: '/course/supply/product/detail', //短视频商品列表
  svInfo: '/course/supply/sv/info', //短视频编辑信息
  svUpdate: '/course/supply/sv/update', //短视频编辑信息
  productCertPage: '/course/supply/product/cert/page', //商品列表
  productCertCategory: '/course/product/attr/category/cert/options', //证书类目
  listReviewByCategory: '/course/supply/cert/listReviewByCategory', //类目下的证书（创建商品）
  productCertCreate: '/course/supply/product/cert/create', //类目下的证书（创建商品）
  productCertInfoSave: '/course/supply/product/cert/info/save', //证书信息保存
  productCertSpecList: '/course/supply/product/cert/specification/list', //证书商品规格列表 动态列
  productSkuSave: '/course/supply/product/sku/save', //证书商品规格列表 保存列
  productSkuRemove: '/course/supply/product/sku/remove', //证书商品规格列表 删除列
  productCertInfo: '/course/supply/product/cert/info', //证书商品详情
  productCertSubmit: '/course/supply/product/cert/submit', //证书商品发布
  productCertCategoryTree: '/course/product/attr/category/cert/tree', //证书类目选项列表(树结构)
  productCertExport: '/course/supply/product/cert/export', //证书商品列表导出
  productCertPublish: '/course/supply/product/cert/publish/batch', //证书商品上下架
  productRemove: '/course/supply/product/remove', //删除商品
  agentInfo: '/course/identify/agent/order/agentInfo', ///查询代理商个人信息
  agentSumAmount: '/course/identify/agent/order/sumAmount', ///代理商销售总额
  agentOrderPage: '/course/identify/agent/order/page', ///分页查询当前机构下当前代理商订单
  agentOrderExport: '/course/identify/agent/order/export', ///导出当前机构下当前代理商订单
  offlineSalesRecord: '/course/offline/sales/Record/select/page', ///线下订单导入分页查询
  offlineSalesRecordExport: '/course/offline/sales/Record/excel/export', ///线下订单导出
  offlineSalesRecordImport: '/course/offline/sales/Record/import/excel', ///线下订单导入--excel
  offlineSalesRecordDownload: '/course/offline/sales/Record/download/tempate', ///下载导入模板
  offlineSalesRecordProductSku: '/course/offline/sales/Record/find/productSku', ///查询商品信息
  offlineSalesRecordReg: '/course/supply/offline/sales/record/registration/page', ///查询商品信息
  distributorList: '/course/supply/distributor/list', ///获取机构下的分销商列表
  offlineSalesRecordRegExport: '/course/supply/offline/sales/record/registration/export', ///报名记录列表导出
  offlineSalesRecordReview: '/course/supply/offline/sales/record/review/batch', ///报名批量审核
  offlineSalesRecordDelete: '/course/offline/sales/Record/delete/salesRecord', ///删除导入信息
  certCategoryList: '/course/supply/product/cert/category/list', // 证书商品分类
  findProductSaleInfo: '/order/findProductSaleInfo', //銷售詳情
  getAllPayType: '/order/getAllPayType', //支付类型
  exportProductSaleInfo: '/order/exportProductSaleInfo', //导出销售列表
  getSelectButtonProductSkuInfo: '/course/identify/user/calculator/getSelectButtonProductSkuInfo', //获取商品sku
}
