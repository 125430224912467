<template>
  <div class="sales-record">
    <table-list
      title="分销数据"
      :loading="loading"
      :data="dataList"
      :columns="columns(this)"
      :btns="btns(this)"
      :pager="pager"
      :tabs-list="tabList"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
      <div slot="filter" class="subTitle">
        <span class="textDes">累计成交金额：</span>
        <span class="textMon">￥{{ sunAmount }}</span>
      </div>
    </table-list>
  </div>
</template>

<script>
//表格tabs
const tabList = [
  {
    prop: 'timeCheck',
    activeValue: '',
    children: [
      { value: 1, label: '今日' },
      { value: 2, label: '本月' },
      { value: 3, label: '半年' },
    ],
  },
  {
    prop: 'online',
    activeValue: '',
    children: [
      { value: 0, label: '线上' },
      { value: 1, label: '线下' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleexportData,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderIdDetail',
    label: '订单编号',
    minWidth: 190,
  },
  {
    prop: 'productResourceType',
    label: '类型',
    minWidth: 58,
    formatter: row => {
      return { 1: '班级', 2: '课程', 3: '证书' }[row.productResourceType]
    },
  },
  {
    prop: 'productName',
    label: '商品名称',
    minWidth: 160,
    twoLines: true,
  },
  {
    prop: 'skuName',
    label: '规格',
    minWidth: 120,
  },
  {
    prop: 'onLineShow',
    label: '来源',
    minWidth: 48,
  },
  {
    prop: 'productSumAmount',
    label: '订单金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.productSumAmount
    },
  },
  {
    prop: 'amount',
    label: '实付金额',
    minWidth: 100,
    align: 'right',
    formatter: row => {
      return '￥' + row.amount
    },
  },
  {
    prop: 'payTypeName',
    label: '支付渠道',
    minWidth: 76,
  },
  {
    prop: 'payTimeShow',
    label: '支付时间',
    minWidth: 150,
  },
  {
    prop: 'userName',
    label: '购买人名称',
    minWidth: 90,
  },
  {
    prop: 'phone',
    label: '手机号',
    minWidth: 110,
  },
]
import TableList from '@/components/TableList'
import { agentSumAmount, agentOrderPage, agentOrderExport } from '@/api/center'
import to from 'await-to'
export default {
  name: 'SalesRecord',
  components: {
    TableList,
  },
  props: {
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tabList,
      loading: false,
      btns,
      columns,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      queryParams: {},
      dataList: [],
      sunAmount: 0,
    }
  },
  mounted() {
    this.getAmount()
    this.handleOrderInfo()
  },
  methods: {
    async getAmount() {
      const [res, err] = await to(agentSumAmount({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.sunAmount = res.data || '0.00'
    },
    //订单详情查询
    async handleOrderInfo() {
      if (this.loading) return
      const { current, size } = this.pager
      if (!this.queryParams.online && this.queryParams.online !== 0) {
        this.queryParams.online = -1
      }
      if (!this.queryParams.timeCheck && this.queryParams.timeCheck !== 0) {
        this.queryParams.timeCheck = 0
      }
      this.loading = true
      const [res, err] = await to(
        agentOrderPage({
          current,
          size,
          ...this.queryParams,
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.pager.total = res.data.total
      this.dataList = res.data.list
    },
    //查询
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      this.handleOrderInfo()
    },
    //导出
    async handleexportData() {
      const { current, size } = this.pager
      const [res, err] = await to(
        agentOrderExport({
          current,
          size,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message.warning({ type: 'warning', message: err.msg })
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = this.userName
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleOrderInfo()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleOrderInfo()
    },
  },
}
</script>
<style lang="scss" scoped>
.sales-record {
  .subTitle {
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    margin-left: 20px;
    border-left: 1px solid #e2e2e2;
    padding-left: 20px;
    line-height: 20px;
    .textDes {
      color: #909399;
    }
    .textMon {
      color: #ff7b33;
    }
  }
}
</style>
